import React from 'react'
import FacilitiesCard from './CardData/FacilitiesCard'
import PageHeader from './PageHeader'

const FacilitiesPage = () => {
  const facilities = [
    // {
    //   image: 'Images/children-602967_1920.jpg',
    //   title: 'Play home to 10th classes',
    //   description:
    //     'Nurturing young minds from Play Home to 10th. Providing a strong educational foundation.',
    // },
    {
      image: 'Images/labs.jpg',
      title: ' Science Lab',
      description:
        'Explore the wonders of science. Our facilities provide students with the tools they need to conduct experiments and enhance their understanding of scientific principles.',
    },
    {
      image: 'Images/multimedia.jpg',
      title: 'Multimedia room',
      description:
        'Engaging multimedia experiences in our modern studio. Promoting interactive and creative learning',
    },
    {
      image: 'Images/computerlabs.jpg',
      title: 'Computer Lab',
      description:
        "Explore our school's modern computer lab, a wide range of educational software. Ideal for enhancing digital learning and fostering creativity.",
    },
    {
      image: 'Images/artgallery.jpg',
      title: ' Art room',
      description:
        'A vibrant space where creativity comes to life. Encouraging artistic expression and skills.',
    },
    {
      image: 'Images/outdooractivity.jpg',
      title: 'Outdoor activities',
      description:
        'Experience the joy of outdoor activities with our well-designed playgrounds and sports fields,physical exercise and having fun in the fresh air.',
    },
    {
      image: 'Images/activitys.jpg',
      title: ' Indoor activities',
      description:
        'Engage in a variety of indoor activities that foster creativity, teamwork, and learning in a comfortable and controlled environment.',
    },
    {
      image: 'Images/smartclass.jpg',
      title: 'Smart class rooms',
      description:
        'Transforming learning with our interactive smart classrooms. Integrating technology in education.',
    },
    {
      image: 'Images/library.jpg',
      title: ' Library',
      description:
        'A treasure trove of knowledge in our library. Supporting research and lifelong learning.',
    },
    // {
    //   image: 'Images/girl-1345800_1920.jpg',
    //   title: ' Teachers quarters',
    //   description:
    //     'Comfortable living spaces for our dedicated teachers. Ensuring a supportive community.',
    // },
    {
      image: 'Images/wejump.jpg',
      title: 'We jump',
      description:
        'Encouraging physical activity and fun in We Jump. Promoting health and wellness.',
    },
  ]

  return (
    <div>
      <div>
        <PageHeader title="Facilities" backgroundImage={'Images/school.jpg'} />
      </div>
      <div className="text-blue-950 text-center mt-10 mb-10">
        <div className="mt-8 flex flex-wrap justify-center gap-6">
          {facilities.map((facility, index) => (
            <div key={index} className="w-full md:mx-0 mx-4 md:w-1/4">
              <FacilitiesCard {...facility} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FacilitiesPage
