import React from 'react'
import DocumentCard from './DocumentCard'
import PageHeader from './PageHeader'
import { PdfSVG } from './icons/icons'

const PublicDisclosure = () => {
  const pdfs = [
    {
      name: 'Fee Structure of the School',
      url: '/pdfs/FeeStructure.pdf',
      icon: <PdfSVG />,
    },
    {
      name: 'Annual Academic Calendar',
      url: '/pdfs/AnnualAcademicCalender.pdf',
      icon: <PdfSVG />,
    },
    {
      name: 'School Management Committee',
      url: '/pdfs/SMC.pdf',
      icon: <PdfSVG />,
    },
    {
      name: 'Society Registration',
      url: '/pdfs/society_reg.pdf',
      icon: <PdfSVG />,
    },
    {
      name: 'CBSE NOC',
      url: '/pdfs/CBSE_NOC.pdf',
      icon: <PdfSVG />,
    },
    {
      name: 'Building Safety Certificate',
      url: '/pdfs/buildingsafty.pdf',
      icon: <PdfSVG />,
    },
    {
      name: 'Fire & Safety Certificate',
      url: '/pdfs/firesafty.pdf',
      icon: <PdfSVG />,
    },
    {
      name: 'Extension Renewal',
      url: '/pdfs/Extension_Affiliation_cbse.pdf',
      icon: <PdfSVG />,
    },
    {
      name: 'Parent Teachers Association',
      url: '/pdfs/PTA.pdf',
      icon: <PdfSVG />,
    },
    {
      name: 'Affilication Copy',
      url: '/pdfs/AFFILICATION COPY.pdf',
      icon: <PdfSVG />,
    },
    {
      name: 'Drinking Water',
      url: '/pdfs/Drinkingwater.pdf',
      icon: <PdfSVG />,
    },
    // Add more PDFs as needed
  ]

  return (
    <div>
      <PageHeader
        title="Mandatory Public Disclosures"
        backgroundImage={'Images/school.jpg'}
      />
      <div
        data-aos="zoom-out-up"
        className="container mx-auto py-10 text-center max-w-screen-xl"
      >
        <div className="flex flex-wrap justify-center gap-2">
          {pdfs.map((pdf, index) => (
            <DocumentCard
              key={index}
              name={pdf.name}
              url={pdf.url}
              icon={pdf.icon}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default PublicDisclosure
