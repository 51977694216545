import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'aos/dist/aos.css'

const YouTubeAdd = () => {
  const videos = [
    {
      videoSrc: 'https://www.youtube.com/embed/as3D11e9FQI?si=CpqZIOUI3bvu7wuq', // Replace this with your YouTube video link
      title: 'Christa Jyothi School',
      description:
        'Welcome to our school!\nWe are dedicated to providing a high-quality education and fostering a love of learning in all our students.',
    },
  ]

  return (
    <div
      data-aos="fade-right"
      className="mt-12 flex items-center bg-blue-950 justify-center h-[30rem]"
    >
      {' '}
      {/* Adjusted height */}
      <div
        className="relative w-11/12 md:w-3/4 lg:w-2/3 md:h-80 text-emerald-700 rounded-lg overflow-hidden shadow-lg"
        style={{ backgroundColor: 'rgb(222 237 254)' }}
      >
        {videos.map((video, index) => (
          <div
            key={index}
            className="relative flex flex-col md:flex-row justify-center items-center"
          >
            <div className="relative w-full md:w-2/5 h-64 mx-auto bg-white text-emerald-700 items-center rounded-lg overflow-hidden md:left-72 md:mt-8">
              <iframe
                width="100%"
                height="100%"
                src={video.videoSrc}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </div>
            {/* Text overlay */}
            <div className="relative md:absolute md:w-1/2 inset-0 flex flex-col justify-center text-left text-blue-950 z-20 px-4 md:px-8">
              <h1
                className="text-3xl md:text-5xl font-bold mt-3 "
                // style={{ fontFamily: 'Montserrat, Sans-serif' }}
              >
                {video.title}
              </h1>
              <p
                className="mt-4 text-lg md:text-xl text-justify"
                // style={{ fontFamily: 'Montserrat, Sans-serif' }}
              >
                {video.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default YouTubeAdd
