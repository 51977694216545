import React from 'react'
import { ImageCard } from './CardData/Cards'
import PageHeader from './PageHeader'

const AdministrationPage: React.FC = () => {
  return (
    <div>
      <div>
        <PageHeader
          title="Administration"
          backgroundImage={'Images/school.jpg'}
        />
        {/* Other gallery content */}
      </div>
      <div className="p-6  text-blue-950">
        <div className="mb-10">
          <h3 className="text-2xl text-center font-semibold mb-2">
            Our Management
          </h3>
          <ImageCard />
        </div>
        <div>
          {/* <h3 className="text-2xl font-semibold mb-2 text-center">
            Non-Teaching & Office Staffs
          </h3>
          <p className="text-lg">
            [Add content about the non-teaching and office staff here. You can
            include their roles and how they contribute to the daily operations
            of the school.]
          </p> */}
        </div>
      </div>
    </div>
  )
}

export default AdministrationPage
