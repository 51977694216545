import React from 'react'

const ImageCollage: React.FC = () => {
  return (
    <div className="mt-12" style={{ paddingLeft: '15%', paddingRight: '15%' }}>
      <div className="sm:flex items-center sm:space-x-8 mt-5">
        <div data-aos="fade-right" className="sm:w-1/2 relative">
          <div className="grid grid-cols-2 gap-4 border p-3 rounded-lg">
            <div className="relative h-50">
              <img
                src="/Images/students-program1.JPG"
                alt="Gallery Image 1"
                className="w-full h-full object-cover shadow-lg rounded-lg"
              />
            </div>
            <div className="relative h-50">
              <img
                src="/Images/students-program2.JPG"
                alt="Gallery Image 2"
                className="w-full h-full object-cover shadow-lg rounded-lg"
              />
            </div>
            <div className="relative h-50">
              <img
                src="/Images/students-program3.JPG"
                alt="Gallery Image 3"
                className="w-full h-full object-cover shadow-lg rounded-lg"
              />
            </div>
            <div className="relative h-50">
              <img
                src="/Images/students-program4.JPG"
                alt="Gallery Image 4"
                className="w-full h-full object-cover shadow-lg rounded-lg"
              />
            </div>
          </div>
        </div>
        <div data-aos="fade-left" className="sm:w-5/6 relative">
          <div className="sm:flex items-center sm:space-x-8 mt-5">
            <div data-aos="fade-right" className="sm:w-1/2 relative">
              <h1
                className="text-2xl md:text-2xl text-blue-950 font-bold mb-1 "
                // style={{ fontFamily: 'Montserrat, Sans-serif' }}
              >
                Welcome to Our School
              </h1>
            </div>
          </div>
          <div className="content1">
            <p
              className="py-5 lg:pr-16 text-blue-950 text-justify "
              style={{
                fontSize: '18px',
                fontWeight: '500',
              }}
            >
              Our school campus is a vibrant hub of learning and growth,
              designed to inspire and engage students in every aspect of their
              educational journey. From the moment you step onto our campus,
              you'll feel the energy and enthusiasm that permeates our
              community.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageCollage
