export const persons = [
  {
    name: 'Rev. Fr. Augustine Paiampallil',
    occupation: 'President',
    image: 'Images/CardImages/president.jpg',
  },
  {
    name: 'Rev. Fr. Renish',
    occupation: 'Secretary',
    image: 'Images/CardImages/secretary.jpg',
  },
  {
    name: 'Rev. Fr. Biju Naduthottiyil',
    occupation: 'Finance',
    image: 'Images/CardImages/finance.jpg',
  },
  {
    name: 'Rev. Fr. Thomas Ayankudi',
    occupation: 'Administrator and Manager',
    image: 'Images/CardImages/administrator.jpg',
  },
  {
    name: 'Rev. Fr. Sebastin PJ',
    occupation: 'Principal',
    image: 'Images/CardImages/principal.jpg',
  },
  {
    name: 'Rev. Sr. Sini Francis',
    occupation: 'Vice Principal',
    image: 'Images/CardImages/vice-principal2.jpg',
  },
  {
    name: 'Rev. Sr. Sowmya',
    occupation: 'Coordinator',
    image: 'Images/CardImages/co-ordinator.jpg',
  },
]

export const studentsPrograms = [
  {
    image: 'Images/activity1.JPG',
    caption: `In Christa Jyothi School, Arkalgud, Grade 10 students embraced the Guruvandana Program, emphasizing gratitude and respect for teachers. Amidst technological advancements, this initiative underscores the enduring significance of honoring those who shape young minds.`,
  },
  {
    image: 'Images/activity2.JPG',
    caption: `In the semester 1, from the current year 2023-24, the students actively engaged in social work and 
    charity initiative. These efforts have showcased the potential of young individuals to make positive 
    impact on their communities and beyond.
    `,
  },
  {
    image: 'Images/activity3.JPG',
    caption: `Christa Jyothi School, Arkalgud, organized a Free Health Camp and Blood Donation Drive on 02/12/2023, demonstrating its dedication to community well-being. The event united healthcare experts, volunteers, and locals, fostering a culture of compassion and solidarity while promoting health and saving lives. Such initiatives exemplify the school's commitment to serving the community.`,
  },
  {
    image: 'Images/activity4.JPG',
    caption: `WeJump is a global community that nurtures youth to become change makers
    with digital competence and social mindfulness.`,
  },
  {
    image: 'Images/activity5.JPG',
    caption: `Empowering Women Tailoring Class at Christa Jyothi School
    Date: 29/11/2023. Christa Jyothi School's tailoring class fosters women's empowerment through skill development, instilling confidence for entrepreneurial pursuits. Beyond sewing skills, participants gain economic independence, uplifting the community.`,
  },
  {
    image: 'Images/activity6.JPG',
    caption: `Bridging Science and Art: Christa Jyothi School's Exhibition Date: December 2023. Christa Jyothi School showcased an art exhibition featuring children's drawings, complemented by working models in a concurrent science exhibition. The event, attended by parents and notable guests, celebrated the fusion of creativity and innovation.`,
  },
  {
    image: 'Images/activity7.JPG',
    caption: `Cultural & Sports Fest Uniting Talents, Celebrating Diversity. Christa Jyothi School's fest exemplified vibrant cultures and endless opportunities, fostering unity through sports, arts, and cultural showcases. The event's success lies in bringing together students from diverse backgrounds to showcase their talents and forge lasting connections.`,
  },
]
export const studentActivities = [
  {
    image: 'Images/prathibakaranji1.jpg',
    caption: 'Prathiba Karanji',
  },
  {
    image: 'Images/prathibakaranji2.JPG',
    caption: 'Prathiba Karanji',
  },
  {
    image: 'Images/prathibakaranji3.JPG',
    caption: 'Prathiba Karanji',
  },
  {
    image: 'Images/prathibakaranji4.JPG',
    caption: 'Prathiba Karanji',
  },
  {
    image: 'Images/prathibakaranji5.JPG',
    caption: 'Prathiba Karanji',
  },
  {
    image: 'Images/prathibakaranji6.JPG',
    caption: 'Prathiba Karanji',
  },
]
export const studentsAwards = [
  {
    image: 'Images/students-award1.JPG',
    caption: 'Students Awards',
  },
  {
    image: 'Images/students-award2.JPG',
    caption: 'Students Awards',
  },
  {
    image: 'Images/students-award3.JPG',
    caption: 'Students Awards',
  },
  {
    image: 'Images/students-award4.JPG',
    caption: 'Students Awards',
  },
  {
    image: 'Images/students-award5.JPG',
    caption: 'Students Awards',
  },
]

export const studentsTalentHunt = [
  {
    image: 'Images/students-talent1.JPG',
    caption: 'Students Talent Hunt',
  },
  {
    image: 'Images/students-talent2.JPG',
    caption: 'Students Talent Hunt',
  },
  {
    image: 'Images/students-talent3.JPG',
    caption: 'Students Talent Hunt',
  },
  {
    image: 'Images/students-talent4.JPG',
    caption: 'Students Talent Hunt',
  },
  {
    image: 'Images/students-award6.JPG',
    caption: 'Students Talent Hunt',
  },
]
export const galleryData = [
  {
    image: 'Images/students-program1.jpg',
    caption: 'Annual Day Celebration',
  },
  {
    image: 'Images/students-program2.jpg',
    caption: 'Annual Day Celebration',
  },
  {
    image: 'Images/students-program3.jpg',
    caption: 'Annual Day Celebration',
  },
  {
    image: 'Images/students-program4.jpg',
    caption: 'Annual Day Celebration',
  },
  {
    image: 'Images/students-program5.jpg',
    caption: 'Annual Day Celebration',
  },
  // Add more photos with captions here
]


export const toppers = [
  { image: 'images/topper1.jpg', caption: '' },
  { image: 'images/topper2.jpg', caption: '' },
]
export const winners = [
  { image: 'images/winners1.jpg', caption: '' },
  { image: 'images/winners2.jpg', caption: '' },
  { image: 'images/winners3.jpg', caption: '' },
  { image: 'images/winners4.jpg', caption: '' },
  { image: 'images/winners5.jpg', caption: '' },
]

// export const teachers = [
//   {
//     name: 'Mr. Anish',
//     qualification: 'BA/B.ED(HINDI)',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Aneesh.JPG',
//   },
//   {
//     name: 'Anjusree',
//     // qualification: 'MBA, PhD',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Anjusree.JPG',
//   },
//   {
//     name: 'Mrs. Bhavya D S',
//     qualification: 'MA/B.ED (ENG/SOC)',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Bhavya D S.JPG',
//   },
//   {
//     name: 'Bhavya K S',
//     // qualification: 'MBA, PhD',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Bhavya K S.JPG',
//   },
//   {
//     name: 'Chandrakala',
//     // qualification: 'MBA, PhD',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Chandrakala.JPG',
//   },
//   {
//     name: 'Chinnalakshmi',
//     // qualification: 'MBA, PhD',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Chinnalakshmi.JPG',
//   },
//   {
//     name: 'Mr. David Rakshith',
//     qualification: 'BA/B.ED(ENG/SOC)',
//     // position: 'President',
//     imageUrl: 'Images/teachers/David Rakshith.JPG',
//   },
//   {
//     name: 'Hithashree',
//     // qualification: 'MBA, PhD',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Hithashree.JPG',
//   },
//   {
//     name: 'Mrs. Janaki',
//     qualification: 'B.SC/B.ED(MAT/SCI)',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Janaki.JPG',
//   },
//   {
//     name: 'Kalavathi',
//     // qualification: 'MBA, PhD',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Kalavathi.JPG',
//   },
//   {
//     name: 'Mr. kumar',
//     qualification: 'MFA(ARTS)',
//     // position: 'President',
//     imageUrl: 'Images/teachers/kumar.JPG',
//   },
//   {
//     name: 'Mr. Mohan kumar',
//     qualification: 'M.SC/B.ED',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Mohan kumar.JPG',
//   },
//   {
//     name: 'Mrs Nandini C C',
//     qualification: 'BA/B.ED(KAN)',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Nandini.JPG',
//   },
//   {
//     name: 'Nanjagowda',
//     // qualification: 'MBA, PhD',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Nanjagowda.JPG',
//   },
//   {
//     name: 'Mr. Nayadin P P',
//     qualification: 'B.SC(ELECTRONICS)',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Nayadin P P.JPG',
//   },
//   {
//     name: 'Palani',
//     // qualification: 'MBA, PhD',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Palani.JPG',
//   },
//   {
//     name: 'Mrs. Prathima',
//     qualification: 'MA/B.ED(KAN)',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Prathima.JPG',
//   },
//   {
//     name: 'Mr. Praveen Kumar M R',
//     qualification: 'BA/B.ED(SOCIAL)',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Praveen.JPG',
//   },
//   {
//     name: 'Punith',
//     // qualification: 'MBA, PhD',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Punith.JPG',
//   },
//   {
//     name: 'Mrs. Rekha M V',
//     qualification: 'MA/D.ED(KAN/SOC)',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Rekha.JPG',
//   },
//   {
//     name: 'Mrs. Roopa H K',
//     qualification: 'MA/TCH(KAN/HIN)',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Roopa.JPG',
//   },
//   {
//     name: 'Sadhana',
//     // qualification: 'MBA, PhD',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Sadhana.JPG',
//   },
//   {
//     name: 'Mrs. Savilshree E V',
//     qualification: 'M.SC/ B.ED(IT)',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Savilsree.JPG',
//   },
//   {
//     name: 'Suchithra A S',
//     // qualification: 'MBA, PhD',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Suchithra A S.JPG',
//   },
//   {
//     name: 'Mrs. Sudharani A G ',
//     qualification: ' M.SC/ B.ED(SCI/MAT)',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Sudharani.JPG',
//   },
//   {
//     name: 'Mrs.Sukanya G S',
//     qualification: 'MA/B.ED (ENG/SOC)',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Sukanya.JPG',
//   },
//   {
//     name: 'Sunitha',
//     // qualification: 'MBA, PhD',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Sunitha.JPG',
//   },
//   {
//     name: 'Sushma',
//     // qualification: 'MBA, PhD',
//     // position: 'President',
//     imageUrl: 'Images/teachers/Sushma.JPG',
//   },
//   {
//     name: 'Yashaswini H S',
//     // qualification: 'MBA, PhD',
//     // position: 'President',
//     imageUrl: 'Images/teachers/YASHASWINI HS.JPG',
//   },

//   // Add more teachers as needed
// ]
