import React from 'react'

export const Footer = () => {
  return (
    <footer className="" style={{ backgroundColor: '#221549' }}>
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <a href="https://flowbite.com/" className="flex items-center">
              <div>
                <img
                  data-aos="fade-up"
                  data-aos-once="true"
                  className="h-20 me-5"
                  src="Images/LOGO PNG WHITE font.png"
                  alt="School"
                />
              </div>
            </a>
          </div>
          <div>
            <h1 className="mb-1 text-lg font-semibold text-white text-decoration-line: underline">
              School Address
            </h1>
            <h2 className="mb-6 text-base text-white">
              <p>Christa Jyothi School</p>
              <p>Kellur, H.N.Pura Road</p>
              <p>Arkalgud, Hassan, Karnataka - 573102</p>
            </h2>
          </div>
        </div>
        <hr className="my-2 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-1" />
        <div className="flex justify-center">
          <div className="item-center mt-4">
            <div className="flex flex-wrap sm:flex-nowrap">
              <a
                href="https://www.facebook.com/Christajyothi"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                target="_blank"
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 8 19"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Facebook page</span>
              </a>
              <a
                href="https://www.youtube.com/channel/UCcgI7g8WUWa0rI-Vm5gShVw"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
                target="_blank"
              >
                <svg
                  className="w-5 h-5"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 461.001 461.001"
                  xmlSpace="preserve"
                  fill="currentColor"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {' '}
                    <g>
                      {' '}
                      <path d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728 c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137 C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607 c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"></path>{' '}
                    </g>{' '}
                  </g>
                </svg>
                <span className="sr-only">Youtube page</span>
              </a>
              <a
                href="https://www.instagram.com/christajyothischool96/"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
                target="_blank"
              >
                <svg
                  className="w-5 h-5"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 551.034 551.034"
                  xmlSpace="preserve"
                  fill="currentColor"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {' '}
                    <g id="XMLID_13_">
                      {' '}
                      <linearGradient
                        id="XMLID_2_"
                        gradientUnits="userSpaceOnUse"
                        x1="275.517"
                        y1="4.5714"
                        x2="275.517"
                        y2="549.7202"
                        gradientTransform="matrix(1 0 0 -1 0 554)"
                      >
                        {' '}
                        <stop offset="0"></stop> <stop offset="0.3"></stop>{' '}
                        <stop offset="0.6"></stop> <stop offset="1"></stop>{' '}
                      </linearGradient>{' '}
                      <path
                        id="XMLID_17_"
                        d="M386.878,0H164.156C73.64,0,0,73.64,0,164.156v222.722 c0,90.516,73.64,164.156,164.156,164.156h222.722c90.516,0,164.156-73.64,164.156-164.156V164.156 C551.033,73.64,477.393,0,386.878,0z M495.6,386.878c0,60.045-48.677,108.722-108.722,108.722H164.156 c-60.045,0-108.722-48.677-108.722-108.722V164.156c0-60.046,48.677-108.722,108.722-108.722h222.722 c60.045,0,108.722,48.676,108.722,108.722L495.6,386.878L495.6,386.878z"
                      ></path>{' '}
                      <linearGradient
                        id="XMLID_3_"
                        gradientUnits="userSpaceOnUse"
                        x1="275.517"
                        y1="4.5714"
                        x2="275.517"
                        y2="549.7202"
                        gradientTransform="matrix(1 0 0 -1 0 554)"
                      >
                        {' '}
                        <stop offset="0"></stop> <stop offset="0.3"></stop>{' '}
                        <stop offset="0.6"></stop> <stop offset="1"></stop>{' '}
                      </linearGradient>{' '}
                      <path
                        id="XMLID_81_"
                        d="M275.517,133C196.933,133,133,196.933,133,275.516 s63.933,142.517,142.517,142.517S418.034,354.1,418.034,275.516S354.101,133,275.517,133z M275.517,362.6 c-48.095,0-87.083-38.988-87.083-87.083s38.989-87.083,87.083-87.083c48.095,0,87.083,38.988,87.083,87.083 C362.6,323.611,323.611,362.6,275.517,362.6z"
                      ></path>{' '}
                      <linearGradient
                        id="XMLID_4_"
                        gradientUnits="userSpaceOnUse"
                        x1="418.306"
                        y1="4.5714"
                        x2="418.306"
                        y2="549.7202"
                        gradientTransform="matrix(1 0 0 -1 0 554)"
                      >
                        {' '}
                        <stop offset="0"></stop> <stop offset="0.3"></stop>{' '}
                        <stop offset="0.6"></stop> <stop offset="1"></stop>{' '}
                      </linearGradient>{' '}
                      <circle
                        id="XMLID_83_"
                        cx="418.306"
                        cy="134.072"
                        r="34.149"
                      ></circle>{' '}
                    </g>{' '}
                  </g>
                </svg>
                <span className="sr-only">Instagram page</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </footer>
  )
}
