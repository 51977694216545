import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [visible, setVisible] = useState(true)
  const [isNavOpen, setIsNavOpen] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10)
      setPrevScrollPos(currentScrollPos)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [prevScrollPos, visible])

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen)
  }
  const closeNav = () => {
    setIsNavOpen(false)
  }

  return (
    <div>
      <div
        className={`w-full text-white h-24 fixed top-0 z-50 transition-transform duration-200 ${
          !visible && '-translate-y-full'
        }`}
        style={{ backgroundColor: '#221549' }}
      >
        <div className="max-w-screen-x2 mx-auto flex items-center justify-between h-24 px-4">
          <div>
            <img
              className=""
              src="Images/LOGO PNG WHITE font.png"
              alt="School"
              width="40%"
              height="auto"
            />
          </div>
          <nav className="flex items-center">
            <div className="hidden sm:flex space-x-2 ">
              <Link
                to="/"
                className="text-white hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
              >
                Home
              </Link>
              <Link
                to="/about"
                className="text-white hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium text-nowrap"
              >
                About Us
              </Link>
              <Link
                to="/administration"
                className="text-white hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
              >
                Administration
              </Link>
              <Link
                to="/achievements"
                className="text-white hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
              >
                Achievements
              </Link>
              <Link
                to="/facilities"
                className="text-white hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
              >
                Facilities
              </Link>
              {/* <Link
                to="#"
                className="text-white hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
              >
                Admissions
              </Link> */}
              <Link
                to="/gallery"
                className="text-white hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
              >
                Gallery
              </Link>
              <Link
                to="/cbse"
                className="text-white hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
              >
                CBSE
              </Link>
            </div>
            <div className="sm:hidden">
              <button
                onClick={toggleNav}
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded={isNavOpen}
              >
                <span className="sr-only">Open main menu</span>
                {!isNavOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </nav>
        </div>
        {isNavOpen && (
          <div
            className="sm:hidden text-white"
            style={{ backgroundColor: '#221549' }}
          >
            <div className="px-2 pt-2 pb-3 space-y-1 ">
              <Link
                to="/"
                className="block px-3 py-2 rounded-md text-base font-medium hover:bg-blue-700"
                onClick={closeNav}
              >
                Home
              </Link>
              <Link
                to="/about"
                className="block px-3 py-2 rounded-md text-base font-medium hover:bg-blue-700 text-nowrap"
                onClick={closeNav}
              >
                About Us
              </Link>
              <Link
                to="/administration"
                className="block px-3 py-2 rounded-md text-base font-medium hover:bg-blue-700"
                onClick={closeNav}
              >
                Administration
              </Link>
              <Link
                to="/achievements"
                className="block px-3 py-2 rounded-md text-base font-medium hover:bg-blue-700"
                onClick={closeNav}
              >
                Achievements
              </Link>
              <Link
                to="/facilities"
                className="block px-3 py-2 rounded-md text-base font-medium hover:bg-blue-700"
                onClick={closeNav}
              >
                Facilities
              </Link>
              {/* <Link
                to="#"
                className="block px-3 py-2 rounded-md text-base font-medium hover:bg-blue-700"
                onClick={closeNav}
              >
                Admissions
              </Link> */}
              <Link
                to="/gallery"
                className="block px-3 py-2 rounded-md text-base font-medium hover:bg-blue-700"
                onClick={closeNav}
              >
                Gallery
              </Link>
              <Link
                to="/cbse"
                className="block px-3 py-2 rounded-md text-base font-medium hover:bg-blue-700"
                onClick={closeNav}
              >
                CBSE
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Header
