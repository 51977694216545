import React from 'react'
import FacilitiesCard from './CardData/FacilitiesCard'
import { useNavigate } from 'react-router-dom'

const FacilitiesHomePage = () => {
  const navigate = useNavigate()
  const facilities = [
    {
      image: 'Images/smartclass.jpg',
      title: 'Smart class rooms',
      description:
        'Transforming learning with our interactive smart classrooms. Integrating technology in education.',
    },
    {
      image: 'Images/wejump.jpg',
      title: 'We jump',
      description:
        'Encouraging physical activity and fun in We Jump. Promoting health and wellness.',
    },
    {
      image: 'Images/computerlabs.jpg',
      title: 'Computer Lab',
      description:
        "Explore our school's modern computer lab, a wide range of educational software. Ideal for enhancing digital learning and fostering creativity.",
    },
  ]
  const handleViewMore = () => {
    navigate('/facilities')
  }

  return (
    <div className="text-blue-950 text-center mt-12">
      <h1 className="text-2xl md:text-3xl font-bold ">Facilities</h1>
      <div className="flex flex-wrap justify-center gap-10 mx-4 md:mx-4 lg:mx-6">
        {facilities.map((facility, index) => (
          <div key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
            <FacilitiesCard {...facility} />
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={handleViewMore}
          className="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-full mt-2 "
        >
          View More
        </button>
      </div>
    </div>
  )
}

export default FacilitiesHomePage
