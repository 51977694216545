import React from 'react'

interface FacilitiesCardProps {
  image: string
  title: string
  description: string
}

const FacilitiesCard: React.FC<FacilitiesCardProps> = ({
  image,
  title,
  description,
}) => {
  return (
    <div
      data-aos="fade-up"
      className="w-full sm:w-80 md:w-96 h-[30rem] shadow-lg flex flex-col items-center rounded-lg border overflow-hidden bg-white md:m-4 lg:m-6"
    >
      <div className="w-full h-64 md:h-72 lg:h-80">
        <img
          className="w-full h-full object-cover"
          src={image}
          alt="Facilities"
        />
      </div>
      <div className="p-4 md:p-6 lg:p-8 w-full">
        <h2
          className="text-lg md:text-2xl lg:text-3xl font-bold mb-2 md:mb-4 text-blue-900 "
          // style={{ fontFamily: 'Montserrat, Sans-serif' }}
        >
          {title}
        </h2>
        <p
          className="text-base md:text-md lg:text-md"
          // style={{ fontFamily: 'Montserrat, Sans-serif' }}
        >
          {description}
        </p>
      </div>
    </div>
  )
}

export default FacilitiesCard
