import React, { useEffect, useState } from 'react'
import Header from './Components/Header'
import HeroSection from './Components/HeroSection'
import Aos from 'aos'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import 'aos/dist/aos.css'
import { Footer } from './Components/Footer'
import './App.css'
import ConnectUs from './ConnectUs'
import PublicDisclosure from './Components/PublicDisclosure'
import FacilitiesHomePage from './Components/FacilitiesHomePage'
import YouTubeAdd from './Components/YouTubeAdd'
import GallerySection from './Components/Gellery'
import AboutUsPage from './Components/AboutUs'
import AddOfSchool from './Components/AddOfSchool'
import AdministrationPage from './Components/AdministrationPage'
import AchievementsPage from './Components/AchievementsPage'
import GalleryPage from './Components/GalleryPage'
import FacilitiesPage from './Components/FacilityInDetail'

const App: React.FC = () => {
  const [displayConnectUs, setDisplayConnectUs] = useState(true)
  useEffect(() => {
    Aos.init()
  }, [])
  return (
    <Router>
      <div style={{ overflowX: 'hidden' }} className="relative">
        {!displayConnectUs ? (
          <ConnectUs setDisplayConnectUs={setDisplayConnectUs} />
        ) : (
          <div
            className="phone-call"
            onClick={() => {
              setDisplayConnectUs(false)
            }}
          >
            <svg
              width="85%"
              height="85%"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              fill="#000000"
              className="absolute top-[7.5%] left-[10%]"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {' '}
                <title></title>{' '}
                <g
                  fill="none"
                  fillRule="evenodd"
                  id="页面-1"
                  strokeWidth="1.44"
                >
                  {' '}
                  <g
                    id="导航图标"
                    transform="translate(-251.000000, -207.000000)"
                  >
                    {' '}
                    <g id="编组" transform="translate(251.000000, 207.000000)">
                      {' '}
                      <rect
                        fill="#FFFFFF"
                        fillOpacity="0.01"
                        fillRule="nonzero"
                        height="24"
                        id="矩形"
                        width="24"
                        x="0"
                        y="0"
                      ></rect>{' '}
                      <path
                        d="M18,16 C20.20915,16 22,14.20915 22,12 C22,9.79085 20.20915,8 18,8"
                        id="路径"
                      ></path>{' '}
                      <path
                        d="M18,16 L18,16 C20.20915,16 22,14.20915 22,12 C22,9.79085 20.20915,8 18,8"
                        id="路径"
                        stroke="#ffffff"
                        strokeLinejoin="round"
                        strokeWidth="1.44"
                      ></path>{' '}
                      <path
                        d="M6,8 C3.79086,8 2,9.79085 2,12 C2,14.20915 3.79086,16 6,16"
                        id="路径"
                      ></path>{' '}
                      <path
                        d="M6,8 C3.79086,8 2,9.79085 2,12 C2,14.20915 3.79086,16 6,16 L6,16"
                        id="路径"
                        stroke="#ffffff"
                        strokeLinejoin="round"
                        strokeWidth="1.44"
                      ></path>{' '}
                      <path
                        d="M6,16 L6,15.75 L6,14.5 L6,12 L6,8 C6,4.68629 8.6863,2 12,2 C15.3137,2 18,4.68629 18,8 L18,16 C18,19.3137 15.3137,22 12,22"
                        id="路径"
                        stroke="#ffffff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.44"
                      ></path>{' '}
                    </g>{' '}
                  </g>{' '}
                </g>{' '}
              </g>
            </svg>
          </div>
        )}
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HeroSection />
                <AddOfSchool />
                <FacilitiesHomePage />
                <YouTubeAdd />
                <GallerySection />
              </>
            }
          />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/administration" element={<AdministrationPage />} />
          <Route path="/achievements" element={<AchievementsPage />} />
          <Route path="/facilities" element={<FacilitiesPage />} />
          <Route path="/cbse" element={<PublicDisclosure />} />
          <Route path="/gallery" element={<GalleryPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  )
}

export default App
