import React from 'react'
import './imageSlider.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
interface ImageDetail {
  image: string
  caption: string
}
interface props {
  imgDetails: ImageDetail[]
}
function ImageSlider(props: props) {
  return (
    <Carousel
      autoPlay={true}
      infiniteLoop={true}
      width={'100%'}
      interval={10000}
      swipeable={false}
    >
      {props.imgDetails.map((image, index) => {
        return (
          <div key={index} className="relative">
            <img src={image.image} alt={`Image ${index}`} className="" />
            {image.caption && (
              <>
                <div className="absolute right-0 bottom-0 text-xl text-white">
                  view more
                </div>
                <div className="absolute w-full h-full inset-0 flex items-center justify-center overlay-text opacity-0 hover:opacity-100 bg-black bg-opacity-80 text-white text-sm">
                  {image.caption}
                </div>
              </>
            )}
          </div>
        )
      })}
    </Carousel>
  )
}

export default ImageSlider
