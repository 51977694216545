import React from 'react'
import './DocumentCard.css'

interface DocumentCardProps {
  name: string
  url: string
  icon: React.ReactElement
}

const DocumentCard: React.FC<DocumentCardProps> = ({ name, url, icon }) => {
  return (
    <div className="bg-white border bg-gray-50 rounded-lg shadow-lg p-4 m-2 flex flex-col items-center w-1/2 md:w-60 max-w-xs mx-auto">
      <div className="w-16 h-16 bg-blue-900 rounded-full  flex items-center justify-center mb-4">
        {icon}
      </div>
      <div className="text-center mb-4">
        <h6 className="">{name}</h6>
      </div>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="bg-yellow-400 text-white px-4 py-2 rounded-full hover:bg-yellow-500"
      >
        Download
      </a>
    </div>
  )
}

export default DocumentCard
