import React, { useState } from 'react'
import {
  galleryData,
  studentsTalentHunt,
  studentActivities,
  studentsAwards,
  studentsPrograms,
} from './data'
import Marquee from 'react-fast-marquee'
import PageHeader from './PageHeader'
import GalleryPageComponent from './GalleryPageComponent'
import ImageDialog from './CardData/FullScreenGallery'

const GalleryPage: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

  const handleOpenDialog = (index: number) => {
    setSelectedIndex(index)
  }

  const handleCloseDialog = () => {
    setSelectedIndex(null)
  }

  const handleNext = () => {
    if (selectedIndex !== null) {
      setSelectedIndex((prevIndex) => {
        if (prevIndex !== null) {
          return (prevIndex + 1) % allImages.length
        }
        return prevIndex
      })
    }
  }

  const handlePrev = () => {
    if (selectedIndex !== null) {
      setSelectedIndex((prevIndex) => {
        if (prevIndex !== null) {
          return (prevIndex - 1 + allImages.length) % allImages.length
        }
        return prevIndex
      })
    }
  }

  const allImages = [
    ...galleryData,
    ...studentActivities,
    ...studentsTalentHunt,
    ...studentsAwards,
    ...studentsPrograms,
  ]

  return (
    <div>
      <div>
        <PageHeader title="Our Gallery" backgroundImage={'Images/school.jpg'} />
      </div>
      <div className="min-h-screen p-4 md:p-8 ">
        <h2 data-aos="fade-left" className="text-2xl font-semibold text-center">
          Annual Day Celebration
        </h2>
        <Marquee pauseOnHover>
          <div
            data-aos="fade-left"
            className="flex flex-nowrap rounded gap-4 mr-4"
          >
            {galleryData.map((item, index) => (
              <div key={index}>
                <GalleryPageComponent
                  {...item}
                  onClick={() => handleOpenDialog(index)}
                />
              </div>
            ))}
          </div>
        </Marquee>
        <h2 className="text-2xl font-semibold text-center mt-6">
          Prathiba Karanji
        </h2>
        <Marquee pauseOnHover direction="right">
          <div className="flex flex-nowrap rounded gap-4 mr-4">
            {studentActivities.map((item, index) => (
              <div key={index}>
                <GalleryPageComponent
                  {...item}
                  onClick={() => handleOpenDialog(galleryData.length + index)}
                />
              </div>
            ))}
          </div>
        </Marquee>
        <h2 className="text-2xl font-semibold text-center mt-6">
          Students Talent Hunt
        </h2>
        <Marquee pauseOnHover>
          <div className="flex flex-nowrap rounded gap-4 mr-4">
            {studentsTalentHunt.map((item, index) => (
              <div key={index}>
                <GalleryPageComponent
                  {...item}
                  onClick={() =>
                    handleOpenDialog(
                      galleryData.length + studentActivities.length + index
                    )
                  }
                />
              </div>
            ))}
          </div>
        </Marquee>
        <h2 className="text-2xl font-semibold text-center mt-6">
          Students Awards
        </h2>
        <Marquee pauseOnHover direction="right">
          <div className="flex flex-nowrap rounded gap-4 mr-4">
            {studentsAwards.map((item, index) => (
              <div key={index}>
                <GalleryPageComponent
                  {...item}
                  onClick={() =>
                    handleOpenDialog(
                      galleryData.length +
                        studentActivities.length +
                        studentsTalentHunt.length +
                        index
                    )
                  }
                />
              </div>
            ))}
          </div>
        </Marquee>
        <h2 className="text-2xl font-semibold text-center mt-6">
          Programs Conducted
        </h2>
        <Marquee pauseOnHover>
          <div className="flex flex-nowrap rounded gap-4 mr-4">
            {studentsPrograms.map((item, index) => (
              <div key={index}>
                <GalleryPageComponent
                  {...item}
                  onClick={() =>
                    handleOpenDialog(
                      galleryData.length +
                        studentActivities.length +
                        studentsTalentHunt.length +
                        studentsAwards.length +
                        index
                    )
                  }
                />
              </div>
            ))}
          </div>
        </Marquee>
      </div>
      {selectedIndex !== null && (
        <ImageDialog
          image={allImages[selectedIndex].image}
          caption={allImages[selectedIndex].caption || ''}
          onClose={handleCloseDialog}
          onNext={handleNext}
          onPrev={handlePrev}
        />
      )}
    </div>
  )
}

export default GalleryPage
