import React from 'react'
import { CloseSVG, NextSVG, PrevSVG } from '../icons/icons'

interface DialogProps {
  image: string
  caption: string
  onClose: () => void
  onNext: () => void
  onPrev: () => void
}

const ImageDialog: React.FC<DialogProps> = ({
  image,
  caption,
  onClose,
  onNext,
  onPrev,
}) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80 p-4">
      <div className="bg-opacity-5 p-4 rounded-lg shadow-lg w-full max-w-md md:max-w-3xl">
        <button
          className="text-right mb-2 bg-white rounded-full p-1"
          onClick={onClose}
        >
          <CloseSVG height="20px" width="20px" />
        </button>
        <div className="flex items-center justify-between">
          <button onClick={onPrev} className="mr-2 bg-white rounded-full p-1">
            <PrevSVG height="20px" width="20px" fill="#000000" />
          </button>
          <div className="flex-1 flex justify-center">
            <img
              src={image}
              alt={caption}
              className="w-full h-auto max-h-80 md:max-h-[30rem] rounded-lg object-contain"
            />
          </div>
          <button onClick={onNext} className="ml-2 bg-white rounded-full p-1">
            <NextSVG height="20px" width="20px" fill="#000000" />
          </button>
        </div>
        <p className="mt-2 text-center overflow-y-auto max-h-32 bg-white p-2 rounded-lg text-justify">
          {caption}
        </p>
      </div>
    </div>
  )
}

export default ImageDialog
