import React from 'react'
import { persons } from '../data'

export const ImageCard = () => {
  return (
    <div data-aos="zoom-out-up" className="mx-auto text-center sm:px-3">
      {/* <h1 className="font-bold text-darken my-3 text-2xl">
        <span className="text-blue-950">Administrators</span>
      </h1> */}

      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-center">
          {persons.map((person, index) => (
            <div key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-4">
              <div className="h-auto md:h-[31.5rem] shadow-lg flex flex-col items-center rounded-lg border overflow-hidden bg-white">
                <div className="w-full h-72 sm:h-80 md:h-96">
                  <img
                    className="w-full h-full object-cover"
                    src={person.image}
                    alt={person.name}
                  />
                </div>
                <div className="p-4 w-full">
                  <h2
                    className="text-lg sm:text-xl md:text-2xl font-bold mb-2 text-center text-blue-900"
                    // style={{ fontFamily: 'Montserrat, Sans-serif' }}
                  >
                    {person.name}
                  </h2>
                  <p
                    className="text-base sm:text-lg md:text-lg text-center "
                    // style={{ fontFamily: 'Montserrat, Sans-serif' }}
                  >
                    {person.occupation}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
