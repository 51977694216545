import React from 'react'
import PageHeader from './PageHeader'
import ImageSlider from './ImageSlider'
import { toppers, winners } from './data'

const AchievementsPage: React.FC = () => {
  return (
    <div>
      <div>
        <PageHeader
          title="Achievements"
          backgroundImage={'Images/school.jpg'}
        />
        {/* Other gallery content */}
      </div>
      <div className="p-6 text-blue-950 mt-10">
        <h3 className="text-2xl text-center font-semibold mb-4">
          Medals and certificates
        </h3>
        <div className="mb-10 flex flex-col lg:flex-row lg:items-center">
          <div className="lg:w-1/2">
            <ImageSlider imgDetails={winners} />
          </div>
          <div className="lg:w-1/2 lg:pl-6">
            <p className="text-lg mb-4 md:px-20 text-justify">
              Celebrating the incredible achievements of our students in
              extracurricular fields. Each medal symbolizes a milestone reached
              through determination and hard work, while every certificate
              stands as a testament to outstanding performance and dedication.
              These awards not only recognize past accomplishments but also
              inspire future endeavors. We are proud of all our recipients and
              their remarkable contributions to our school community.
              Congratulations to everyone for their exceptional efforts and
              success!
            </p>
          </div>
        </div>
        <h3 className="text-2xl font-semibold text-center mb-4">
          Toppers in the 10th 2023-24
        </h3>
        <div className="mb-10 flex flex-col lg:flex-row lg:items-center">
          <div className="lg:w-1/2 lg:order-2 lg:pl-6">
            <ImageSlider imgDetails={toppers} />
          </div>
          <div className="lg:w-1/2">
            <p className="text-lg mb-4 md:px-20 text-justify">
              Congratulations to our outstanding 10th-grade toppers of 2023-24!
              Your relentless dedication and hard work have paid off, setting a
              benchmark for excellence. These remarkable achievements reflect
              not only your intellectual prowess but also your perseverance and
              commitment. You have made us all proud and inspired your peers to
              aim higher. Keep striving for greatness in all your future
              endeavors!
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AchievementsPage
