import React from 'react'
import { useNavigate } from 'react-router-dom'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Marquee from 'react-fast-marquee'
import { galleryData } from './data'

const GallerySection = () => {
  const navigate = useNavigate()

  const handleViewMore = () => {
    navigate('/gallery')
  }

  return (
    <div data-aos="fade-left" className="mt-16">
      <h2 className="text-center text-4xl font-bold mb-8 text-blue-950 ">
        Gallery
      </h2>
      <div className="md:w-3/4 mx-auto mb-10">
        <Marquee>
          {galleryData.map((item, index) => (
            <div key={index} className="px-2">
              <div className="relative h-60">
                <img
                  src={item.image}
                  alt={`Gallery Image ${index + 1}`}
                  className="w-full h-full object-cover shadow-lg rounded-lg"
                />
              </div>
            </div>
          ))}
        </Marquee>
        <div className="flex justify-center mt-4">
          <button
            onClick={handleViewMore}
            className="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-full mt-2 "
          >
            View More
          </button>
        </div>
      </div>
    </div>
  )
}

export default GallerySection
