// PageHeader.tsx
import React from 'react'

interface PageHeaderProps {
  title: string
  backgroundImage: string
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, backgroundImage }) => {
  return (
    <div
      className="w-full h-72 bg-cover bg-center flex items-center justify-center text-white"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="bg-blue-800 bg-opacity-75 w-full h-full flex items-center justify-center">
        <h1 className="text-4xl font-bold mt-16 ">{title}</h1>
      </div>
    </div>
  )
}

export default PageHeader
