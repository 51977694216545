import React, { useEffect, useState } from 'react'
import Marquee from 'react-fast-marquee'

const slides = [
  {
    image: 'Images/school.jpg',
    title: 'Caring Your Future, Sharing Your Dreams',
    subtitle:
      'Join our CBSE School for academic excellence and holistic development. Our dedicated educators and comprehensive curriculum ensure success in both academics and personal growth.',
    buttonText: 'Apply Now',
    additionalInfo: 'JOIN US FOR AN ENRICHED EDUCATIONAL JOURNEY, APPLY NOW!',
  },
  {
    image: 'Images/school4.JPG',
    title: 'Empowering Education, Enriching Lives',
    subtitle:
      'Experience quality education at our State School. Our committed faculty and diverse programs foster a love for learning and prepare students for a bright future.',
    buttonText: 'Enroll Today',
    additionalInfo: 'JOIN US FOR AN ENRICHED EDUCATIONAL JOURNEY, APPLY NOW!',
  },
]

const HeroSection: React.FC = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length)
    }, 5000) // Change slide every 5 seconds

    return () => clearInterval(interval)
  }, [])

  const currentSlide = slides[currentSlideIndex]

  return (
    <div
      data-aos="zoom-out-up"
      className="relative w-full h-screen text-white flex items-center justify-start overflow-hidden"
    >
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`absolute inset-0 w-full h-full transition-opacity duration-1000 ${
            currentSlideIndex === index ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <img
            src={slide.image}
            alt={`Slide ${index}`}
            className="w-full h-full object-cover"
          />
        </div>
      ))}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-900 via-blue-900/50 to-transparent"></div>
      <div className="relative z-10 text-left p-6 max-w-3xl md:ml-5">
        <h1 className="text-3xl md:text-6xl font-bold mb-4">
          {currentSlide.title.split(',').map((line, index) => (
            <span
              key={index}
              className={index % 2 === 1 ? 'text-blue-300' : ''}
            >
              {line}
              <br />
            </span>
          ))}
        </h1>
        <p className="text-xl md:text-2xl mb-6 text-justify">{currentSlide.subtitle}</p>
        {/* <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          {currentSlide.buttonText}
        </button> */}
      </div>
      <div className="absolute bottom-0 w-full text-center py-4 bg-blue-800 bg-opacity-75">
        <Marquee>
          <p className="text-sm md:text-lg">{currentSlide.additionalInfo}</p>
        </Marquee>
      </div>
    </div>
  )
}

export default HeroSection
