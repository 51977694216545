import React from 'react'
import PageHeader from './PageHeader'

const AboutUsPage: React.FC = () => {
  return (
    <div>
      <div>
        <PageHeader title="About Us" backgroundImage={'Images/school.jpg'} />
        {/* Other gallery content */}
      </div>
      <div className="px-4 md:px-16 lg:px-64 text-blue-950">
        <div className="flex flex-wrap md:flex-nowrap items-center mb-5">
          <img
            src="/images/insp.png"
            alt="Founder - St. Kuriakose Chavara"
            className="w-full md:w-1/3 mb-5 mt-5 md:mt-0 md:mb-0 md:mr-5 rounded-lg border"
          />
          <div className="w-full md:w-2/3">
            <h2 className="text-center md:text-left text-3xl font-bold mb-2 text-blue-900">
              St. Kuriakose Elias Chavara
            </h2>
            <p className="text-justify">
              Christa Jyothi School is a minority institution managed by the St.
              Thomas Education Society, Mysuru. This institution is organized by
              the C.M.I. Fathers of Mysuru to achieve educational goals. It is
              open to all without any distinction of caste or creed. Its motto
              is Service & Excellence. Our institution is located amidst
              beautiful greenery, provided with well-built infrastructure, good
              conveyances, and eminent teaching faculties. Our prime focus is on
              improving the abilities of the pupils to reach the royal road of
              excellence. "So join us for a better future and build the nation".
            </p>
          </div>
        </div>
        <div className="mb-5">
          <h2 className="text-2xl font-bold mb-2 mt-10">Vision</h2>
          <p className="text-justify">Knowledge and Integrity</p>
        </div>
        <div className="mb-5">
          <h2 className="text-2xl font-bold mb-2 mt-10">Mission</h2>
          <p className="text-justify">
            Christa Jyothi School aspires towards creating a just and humane
            society where the dignity of the human person is respected, unjust
            social structures are challenged, our culture, heritage of ahimsa,
            religious harmony, and national unity are upheld, and the poor and
            the marginalized are specially taken care of.
          </p>
        </div>
        <div className="mb-5">
          <h2 className="text-2xl font-bold mb-2 mt-10">Motto</h2>
          <p className="text-justify">
            Our motto is Knowledge and Integrity. Our school tries to impart
            knowledge so as to make an individual achieve integral growth in
            every field. The aim of the school is not merely preparing its
            pupils for examinations; rather it has a higher and noble goal of
            effectively helping the students, competent in the areas of
            academic, cultural, social, physical, and spiritual life.
          </p>
        </div>
        <div className="mb-5">
          <h2 className="text-2xl font-bold mb-2 mt-10">
            Institutions under Society
          </h2>
          <ul className="list-disc list-inside ml-5">
            <li>Christ School, Hunsur</li>
            <li>Christ Public School, Bogadi</li>
            <li>Christ Public School, Hassan</li>
            <li>Christ School, Thandavapura</li>
            <li>Pushpa School, Periyapattana</li>
            <li>Sacred Heart School, Shanivarasanthe</li>
            <li>Bharath Matha School, Koppa</li>
            <li>St. Thomas School, Gonikoppal</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default AboutUsPage
