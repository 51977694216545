import React from 'react';

interface GalleryCardProps {
  image: string;
  caption: string;
  onClick: () => void;  // New prop for handling click event
}

const GalleryPageComponent: React.FC<GalleryCardProps> = ({ image, caption, onClick }) => {
  return (
    <div className="">
      <div className="">
        <div className="bg-white p-1 cursor-pointer" onClick={onClick}>
          <img src={image} alt={caption} className="w-full h-48 object-cover " />
        </div>
      </div>
    </div>
  );
};

export default GalleryPageComponent;
